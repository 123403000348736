<template>
  <div class="login-container">
    <el-header class="login-header">
      <div class="loginHead-content">
        <img src="../assets/ylcloudlogo.svg" class="largeImg" />
        <el-menu mode="horizontal">
          <el-menu-item index="1">
            <el-link target="_blank" :underline="false"><span class="menuText">首页</span></el-link>
          </el-menu-item>
          <el-menu-item index="2">
            <el-link :href="`${base.ac_url}?source=yclogin`" target="_blank" :underline="false"><span
                class="menuText">营销学院</span></el-link>
          </el-menu-item>
          <el-menu-item index="3">
            <el-link :href="`${base.official_url}/about-us?invite=yclogin`" target="_blank" :underline="false"><span
                class="menuText">关于我们</span></el-link>
          </el-menu-item>
          <el-menu-item index="4">
            <el-link :href="`${base.official_url}/case?invite=yclogin`" target="_blank" :underline="false"><span
                class="menuText">成功案例</span></el-link>
          </el-menu-item>
          <!-- <el-menu-item index="5"
            ><el-link href="https://element.eleme.io" target="_blank" :underline="false"
              ><span class="menuText">合作伙伴</span></el-link
            ></el-menu-item> -->
        </el-menu>
      </div>
    </el-header>
    <div class="passport-wrapper login-wrapper">
      <div class="login-box">
        <div class="whichLogin" @click="changeWX">
          <el-tooltip content="微信登录" placement="left" effect="light" v-if="whichPng">
            <div class="rightqrcode"></div>
          </el-tooltip>
          <el-tooltip content="手机登录" placement="left" effect="light" v-else>
            <div class="rightaccount"></div>
          </el-tooltip>
        </div>
        <!-- 账号密码手机验证码登录区域 -->
        <el-tabs v-model="LoginActiveName" @tab-click="changeLogin" v-if="whichPng">
          <el-tab-pane label="手机验证码登录" name="mobileLogin">
            <!-- 手机验证码表单登录区 -->
            <el-form ref="PhoneLoginFormRef" :model="PhoneLoginForm" :rules="PhoneLoginFormRules" label-width="0px"
              class="login-form">
              <el-form-item prop="phone">
                <el-input prefix-icon="el-icon-phone-outline" placeholder="请输入手机号" v-model="PhoneLoginForm.phone"
                  clearable :validate-event="false"></el-input>
              </el-form-item>
              <el-form-item prop="phoneCode">
                <el-row :gutter="10">
                  <el-col :span="15">
                    <el-input prefix-icon="el-icon-chat-dot-round" v-model="PhoneLoginForm.phoneCode"
                      placeholder="请输入验证码" clearable :validate-event="false"></el-input>
                  </el-col>
                  <el-col :span="9">
                    <el-button :disabled="!rightPhone" @click="getCode">
                      {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                    </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 100%" @click="PhoneLogin">登录
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- 账号密码表单登录区 -->
          <el-tab-pane label="账号密码登录" name="accountLogin">
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login-form">
              <el-form-item prop="username">
                <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入账号" clearable
                  :validate-event="false"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-row :gutter="20">
                  <el-col :span="18">
                    <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password"
                      placeholder="请输入密码" show-password clearable :validate-event="false"></el-input>
                  </el-col>
                  <el-col :span="6">
                    <router-link to="/forgetReset"><span class="LinkText">忘记密码</span></router-link>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 100%" @click="login">登录
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <!-- 二维码登录区域 -->
        <div class="codeBox" style="margin-bottom: 40px" v-else>
          <el-row justify="center" style="margin-bottom: 30px">
            <img :src="wxUrl" alt="" class="qrcodeImg" />
          </el-row>
          <el-row justify="center" align="middle">
            <i class="iconfont icon-weixinfang" style="color: #6bc839"></i>
            <span class="Text">打开微信扫一扫登录</span>
          </el-row>
        </div>
        <el-row align="middle" style="margin-top: 15px">
          <el-checkbox v-model="agreeYino" label="" style="margin-right: 5px"></el-checkbox>
          <div class="agreeBox">
            <span class="Text">登录即表示同意</span>
            <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
              <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
            </el-link>
          </div>
        </el-row>
      </div>
      <div class="login-image">
        <img src="@/assets/bg_3-3.png" alt="" />
      </div>
    </div>

    <!-- 新注册绑定手机号对话框 -->
    <el-dialog v-model="bindNewPhoneDialogVisible" :close-on-click-modal="false" :show-close="false" title="绑定手机号"
      width="30%">
      <!-- 内容主体区域 -->
      <el-form ref="bindNewPhoneFormRef" :model="bindNewPhoneForm" :rules="bindNewPhoneFormRules">
        <el-form-item label="" prop="phone">
          <el-input prefix-icon="el-icon-phone-outline" size="medium" placeholder="请输入手机号"
            v-model.trim="bindNewPhoneForm.phone" clearable :validate-event="false"></el-input>
        </el-form-item>
        <el-form-item prop="phoneCode">
          <el-row :gutter="10">
            <el-col :span="17">
              <el-input prefix-icon="el-icon-chat-dot-round" size="medium" placeholder="请输入验证码"
                v-model="bindNewPhoneForm.phoneCode" clearable :validate-event="false"></el-input>
            </el-col>
            <el-col :span="7" style="text-align: right">
              <el-button size="medium" :disabled="!rightBindPhone" @click="getBindCode">
                {{ countDownBindTime > 0 ? `已发送${countDownBindTime}s` : '获取验证码' }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="medium" type="primary" @click="bindNewPhoneBtn">绑 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-footer class="loginFooter">
      <ul class="footerLink" style="margin-bottom: 20px">
        <router-link to="/hc" class="Text" tag="a" target="_blank"><span class="Text">帮助</span></router-link>
        <el-link :href="`${base.url}/privacy.html`" class="Text" :underline="false" target="_blank"><span
            class="Text">隐私</span></el-link>
        <el-link :underline="false" class="Text" :href="`${base.url}/service.html`" target="_blank"><span
            class="Text">条款</span></el-link>
      </ul>
      <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank"
        style="width: auto; text-align: center">
        <span class="Text">
          杭州益刻网络科技有限公司版权所有Copyright © 2020
          <span><img src="@/assets/officeIcon.png" alt="" /></span>浙ICP备20011628号-1
        </span>
      </el-link>
    </el-footer>
  </div>

  <el-dialog custom-class="addWXUser" v-if="'add-wx-user' === dialogType" v-model="showDialog" title="" width="430px"
    :show-close="false">
    <img src="@/assets/images/close.png" alt="" class="dialog-close" @click="closeAndRefresh">
    <img src="@/assets/images/skip.png" alt="" class="dialog-skip" @click="closeAndRefresh">
    <img class="server_qrcode" :src="live_code" alt="" />
  </el-dialog>
</template>

<script>
import base from '../request/base'
import { getBusiness, refreshUserInfo } from '@/request/api/business'

export default {
  data() {
    // 验证手机号的规则
    let checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入有效的电话号码'))
    }
    return {
      base: base,
      dialogType: '',
      showDialog: false,
      live_code: '',
      userId: '',
      // 表单数据的绑定
      loginForm: {
        username: '',
        password: '',
        token: localStorage.getItem('qbit-token'),
      },
      PhoneLoginForm: {
        phone: '',
        phoneCode: '',
        type: 'code',
        token: localStorage.getItem('qbit-token'),
      },
      LoginActiveName: 'mobileLogin',
      agreeYino: true,
      whichPng: true,
      countDownTime: 0,
      // 设置表单验证规则
      loginFormRules: {
        username: [{ required: true, message: '请输入账号' }],
        password: [
          { required: true, message: '请输入登录密码' },
          {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符',
            trigger: 'blur',
          },
        ],
      },
      PhoneLoginFormRules: {
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile },
        ],
        phoneCode: [{ required: true, message: '请输入验证码' }],
      },
      // 用户信息
      userInfo: {},
      // 微信二维码图片
      wxUrl: '',
      wxKey: '',
      // 微信二维码定时器
      wxClock: undefined,
      // 绑定手机号对话框
      bindNewPhoneDialogVisible: false,
      bindNewPhoneForm: {
        phone: '',
        phoneCode: '',
      },
      bindNewPhoneFormRules: {
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile },
        ],
        phoneCode: [{ required: true, message: '请输入正确的验证码' }],
      },
      countDownBindTime: 0,
      trace: '',
    }
  },
  created() {
    this.getURLParams(window.location.href)
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        if (this.userInfo.crm_id > 0 || this.userInfo.q_type > 0) {
          this.$router.push('/home')
        } else {
          this.$router.push('/users')
        }
      }
    }
  },
  methods: {
    inviteBusinessAccount() {
      // 企业子账户邀请链接进入判断逻辑
      if (window.location.search.split('token=')[1]) {
        const token = window.location.search.split('token=')[1]
        getBusiness(token, 1).then(async response => {
          const res = await refreshUserInfo()
          window.localStorage.setItem('userInfo', JSON.stringify(res.user))
          this.$store.dispatch('EDIT_USERINFO', res.user)
          this.$router.push('/home')
          if (response.code === 200) this.$message.success('加入企业成功')
        })
      }
    },
    closeAndRefresh() {
      this.dialogType = ''
      // cosmic客户子账号进入账户列表页
      if (this.$store.getters.userInfo.manage !== 1 && (this.$store.getters.userInfo.crm_id === 91122 || this.$store.getters.userInfo.crm_id === 27563)) {
        this.$router.push('/advertising_list')
        return
      }
      if (window.location.search.split('token=')[1]) return this.inviteBusinessAccount()
      if (this.$store.getters.userInfo.crm_id > 0 || this.$store.getters.userInfo.q_type > 0) {
        if (this.$route.query.goto) return this.$router.push(this.$route.query.goto)
        this.$router.push('/home')
      } else {
        this.$router.push('/users')
      }
    },
    async addWxUser(res, refresh = true) {
      localStorage.removeItem('qbit-token')
      if (!res.user || !res.user.phone || res.user.q_type === 1 || res.user.exc === 'qbit') {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        if (refresh) this.closeAndRefresh()
        return
      }

      const result = await this.$http.get('https://scrm-wx.weiling.cn/client/mobile/corp_friend', {
        params: {
          corp_id: 'wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg',
          mobile: !res.user || !res.user.phone ? '' : res.user.phone,
        },
      })

      if (result.code == 200 && result.data == true) {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        if (refresh) this.closeAndRefresh()
        return
      }

      let name = res.user.name == null ? '' : res.user.name
      let phone = res.user.phone == null ? '' : res.user.phone
      let company = res.user.company == null ? '' : res.user.company
      let email = res.user.email == null ? '' : res.user.email
      wlRegisterComp.linkToWL(
        {
          wl_qrcode_id: '1605811139499880448', // 需要使用的智能码ID
          client_user_id: res.user.phone, // 用户在贵公司平台的唯一
          sign_mode: false, // 可选参数 不传时默认false 是否校验签名
          sign: '', //string 可选参数 sign_mode为true时传输 加密后的签名
          current_time: 0, //可选参数 sign_mode为true时传输 加密签名时使用的毫秒时间戳
          formData: {
            联系人: name,
            手机号: phone,
            公司名称: company,
            邮箱: email,
          }, // 用户填写的注册或登录信息，value可以为空，但key值必填
          is_update: false, // 此次提交是否为数据更新，默认false
        },
        (data) => {
          if (data.msg == '当前用户已加好友' || data.code !== 200) {
            window.localStorage.setItem('userInfo', JSON.stringify(res.user))
            this.$store.dispatch('EDIT_USERINFO', res.user)
            if (refresh) this.closeAndRefresh()
            return
          }
          if (data.code !== 200) {
            window.localStorage.setItem('userInfo', JSON.stringify(res.user))
            this.$store.dispatch('EDIT_USERINFO', res.user)
            return
          }
          this.live_code = data.data.live_code
          wlRegisterComp.checkUserState(
            res.user.phone, // 用户在客户平台的唯一ID
            (data) => {
              window.localStorage.setItem('userInfo', JSON.stringify(res.user))
              this.$store.dispatch('EDIT_USERINFO', res.user)
              this.inviteBusinessAccount()
              if (data.data.is_added == false) {
                this.dialogType = 'add-wx-user'
                this.showDialog = true
              } else {
                this.dialogType = ''
                this.showDialog = false
              }
            }
          )
        }
      )
    },
    async getURLParams(url) {
      let cs = url.split('?')[1]
      if (cs) {
        let cc = cs.split('=')[0]
        if (cc == 'goto') return null
        if (cc == 'trace') {
          let cc1 = cs.split('=')[1]
          this.trace = cc1
          window.localStorage.setItem('trace', cc1)
          const { data: res } = await this.$http.post('login/trace', { key: this.trace, type: '点击链接' })
        }

        this.setId = cs
        const { data: res } = await this.$http.post('login/get_user', { keyword: this.setId })
        if (res.code == 200) {
          // this.$message.success('登录成功')
          await this.addWxUser(res)
          // 将登录成功后的token，保存到客户端的localStorage中
        }
      }
    },
    // 密码登录
    login() {
      this.$refs.loginFormRef.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await this.$http.post('login/p_login', this.loginForm)
        this.userInfo = res.user
        if (res.code != 200) return this.$message.error(`${res.msg}`)
        let trace = window.localStorage.getItem('trace')
        if (trace) {
          const traceDate = await this.$http.post('login/trace', { key: trace, type: '登录', token: res.user.token })
        }
        let source = window.localStorage.getItem('source')
        if (source) {
          let routeData = this.$router.resolve({ path: '/wj' })
          window.open(routeData.href, '_blank')
        }
        // this.$message.success('登录成功')
        // 将登录成功后的token，保存到客户端的localStorage中
        await this.addWxUser(res)
      })
    },

    // 获取验证码
    async getCode() {
      // 如果当前没有计时
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        const clock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(clock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)

        // 发送axios请求
        const { data: res } = await this.$http.post('login/login', this.PhoneLoginForm)
      }
    },
    // 验证码登录
    PhoneLogin() {
      this.$refs.PhoneLoginFormRef.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await this.$http.post('login/login', {
          phone: this.PhoneLoginForm.phone,
          phoneCode: this.PhoneLoginForm.phoneCode,
          token: this.PhoneLoginForm.token,
        })

        if (res.code != 200) return this.$message.error(`${res.msg}`)
        let trace = window.localStorage.getItem('trace')
        if (trace) {
          const traceDate = await this.$http.post('trace', { key: trace, type: '登录', token: res.user.token })
        }
        let source = window.localStorage.getItem('source')
        if (source) {
          let routeData = this.$router.resolve({ path: '/wj' })
          window.open(routeData.href, '_blank')
        }
        await this.addWxUser(res)
      })
    },
    changeLogin() {
      this.$refs.loginFormRef.resetFields()
      this.$refs.PhoneLoginFormRef.resetFields()
    },

    changeWX() {
      this.whichPng = !this.whichPng
      if (this.whichPng == true) {
        clearInterval(this.wxClock)
      } else {
        this.wxImg()
      }
    },
    // 获取微信二维码
    async wxImg() {
      const { data: res } = await this.$http.get('login/wxImg')
      this.wxUrl = res.data.url
      this.wxKey = res.data.key
      this.wxLogin()
    },
    // 微信登录
    wxLogin() {
      this.wxClock = window.setInterval(async () => {
        const { data: res } = await this.$http.post('login/wxLogin', {
          titcket: this.wxKey,
          token: localStorage.getItem('qbit-token'),
        })
        if (res.code == 200) {
          clearInterval(this.wxClock)
          if (res.user.phone == '' || res.user.phone == null) {
            this.userId = res.user.id
            this.bindNewPhoneDialogVisible = true
          } else {
            let trace = window.localStorage.getItem('trace')
            if (trace) {
              const traceDate = await this.$http.post('login/trace', {
                key: trace,
                type: '登录',
                token: res.user.token,
              })
            }
            let source = window.localStorage.getItem('source')
            if (source) {
              let routeData = this.$router.resolve({ path: '/wj' })
              window.open(routeData.href, '_blank')
            }
            await this.addWxUser(res)
          }
        }
      }, 1000)
    },
    // 获取绑定手机验证码
    async getBindCode() {
      // 如果当前没有计时
      if (!this.countDownBindTime) {
        // 启动倒计时
        this.countDownBindTime = 60
        const clock = window.setInterval(() => {
          this.countDownBindTime--
          if (this.countDownBindTime <= 0) {
            clearInterval(clock)
          }
          this.content = this.countDownBindTime + 's后重新发送'
        }, 1000)

        // 发送axios请求
        const { data: res } = await this.$http.post('login/login', {
          phone: this.bindNewPhoneForm.phone,
          type: 'code',
        })
      }
    },
    // 确认绑定按钮
    bindNewPhoneBtn() {
      this.$refs.bindNewPhoneFormRef.validate(async (valid) => {
        if (!valid) return

        const { data: res } = await this.$http.post('login/edit_phone', {
          phone: this.bindNewPhoneForm.phone,
          phoneCode: this.bindNewPhoneForm.phoneCode,
          user_id: this.userId,
          type: 3,
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        let trace = window.localStorage.getItem('trace')
        if (trace) {
          const traceDate = await this.$http.post('login/trace', { key: trace, type: '注册', token: res.user.token })
        }
        this.$message.success('绑定成功')
        await this.addWxUser(res)
      })
    },
  },
  computed: {
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.PhoneLoginForm.phone
      )
    },
    rightBindPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.bindNewPhoneForm.phone
      )
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.login-container {
  height: 100%;
}

.login-header {
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  padding: 0 60px;
  z-index: 2000;

  .loginHead-content {
    display: flex;
    justify-content: space-between;
  }

  .largeImg {
    width: 200px;
    height: 40px;
    margin-top: 10px;
  }

  .el-menu-item {
    padding: 0 30px;
  }

  .el-link--inner {
    font-size: 16px;
    color: #262626;
  }
}

.el-menu--horizontal {
  width: 100%;
  justify-content: end;
  background: rgba(255, 255, 255, 0);
  border-bottom: none;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff;
}

.login-box {
  width: 430px;
  height: 460px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 90px 40px 0;
  box-sizing: border-box;
  z-index: 1;
}

.menuText {
  font-size: 16px;
  color: #262626;
}

.login-image {
  width: 700px;
  height: auto;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);

  img {
    width: 100%;
  }
}

.agreeBox {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.login-form {
  width: 100%;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.whichLogin {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.rightqrcode {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url('../assets/toqrcode.png') no-repeat;
  cursor: pointer;
}

.rightqrcode:hover {
  background: url(../assets/toqrcodeh.png) no-repeat;
}

.rightaccount {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url('../assets/toaccount.png') no-repeat;
  cursor: pointer;
}

.rightaccount:hover {
  background: url(../assets/toaccounth.png) no-repeat;
}

.codeBox .qrcodeImg {
  width: 200px;
}

.loginFooter {
  position: fixed;
  bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.loginFooter .footerLink {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.login-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  background: url('../assets/psdBG.png') no-repeat;
  background-size: 100%;
}

.passport-wrapper {
  position: relative;
  height: 100vh;
  min-width: 1200px;
  min-height: 700px;
}

.bindWeChat {
  :deep(.el-dialog__body) {
    padding-top: 0;
  }
}
</style>
<style>
.addWXUser {
  height: 560px !important;
  background: url('../assets/images/popBG.png') no-repeat;
  background-size: 100%;
  position: relative;
}

.addWXUser .el-dialog__header {
  padding: 0 !important;
  border: 0 !important;
}

.addWXUser .el-dialog__body {
  padding: 0 !important;
}

.dialog-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.dialog-skip {
  width: 108px;
  height: 40px;
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.server_qrcode {
  width: 268px;
  height: 268px;
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
