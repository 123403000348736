import axios from '@/request/http'
import base from '@/request/base'

/**
 * 获取用户组子账户列表
 */
export const getUserGroupList = (data) => {
  return axios.get(`${base.admin_url}/cloud/user/get_user_group`, {
    params: {
      type: data && data.type ? data.type : null,
      id: data && data.id ? data.id : null,
      keyword: data && data.keyword ? data.keyword : null,
      page: data && data.page ? data.page : 1,
      pagesize: data && data.pagesize ? data.pagesize : 10,
    },
  })
}

/**
 * 获取用户组
 */
export const getUserGroup = () => {
  return axios.get(`${base.admin_url}/cloud/user/user_group`)
}

/**
 * 编辑用户组名称
 */
export const editUserGroup = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/edit_user_group`, data)
}

/**
 * 新增用户组
 */
export const addUserGroup = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/add_user_group`, data)
}

/**
 * 删除用户组
 */
export const deleteUserGroup = (id) => {
  return axios.post(`${base.admin_url}/cloud/user/delete_user_group?id=${id}`)
}

/**
 * 移出用户组
 */
export const moveoutGroup = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/moveout_user`, data)
}

/**
 * 修改成员权限
 */
export const editUserPermission = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/edit_user_rule`, data)
}

/**
 * 批量添加用户组
 */
export const batchAddGroupUser = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/add_user_rule`, data)
}

/**
 * 获取邀请链接
 */
export const getInviteUrl = () => {
  return axios.post(`${base.admin_url}/cloud/user/set_invite`)
}

/**
 * 删除企业子账户
 * @param {Number} id
 * @returns
 */
export const deleteAccount = (id) => {
  return axios.post(`${base.admin_url}/cloud/user/delete_sub_account`, { id })
}

/**
 * 未登录时获取企业信息，登录时注册企业子账户
 * @param {String} token 邀请链接token
 * @param {Number} type 1:登录时注册企业子账户，2:未登录时获取企业信息
 * @returns
 */
export const getBusiness = (token, type) => {
  return axios.post(`${base.admin_url}/cloud/login/set_company`, { token, type })
}

/**
 * 加入企业子账户后刷新个人信息
 */
export const refreshUserInfo = () => {
  return axios.post(`${base.admin_url}/cloud/user/get_user`)
}

/**
 * 获取角色权限列表
 * @param {Number} group_id 角色id
 */
export const getRolePermission = (group_id) => {
  return axios.get(`${base.admin_url}/cloud/user/rule?group_id=${group_id}`)
}

/**
 * 编辑角色权限
 * @param {Number} group_id 角色id
 * @param {String} rule_ids 选择的权限id，多个用逗号隔开
 * @param {Number} is_account 是否查看全部广告账户，1:是，0:否
 */
export const editRolePermission = (group_id, rule_ids, is_account) => {
  return axios.post(`${base.admin_url}/cloud/user/add_rule`, { group_id, rule_ids, is_account })
}

/**
 * 获取全部角色
 */
export const getRoleList = (data) => {
  return axios.get(`${base.admin_url}/cloud/user/role`, {
    params: {
      page: data && data.page ? data.page : 1,
      pagesize: data && data.pagesize ? data.pagesize : 10,
    },
  })
}

/**
 * 修改成员权限中获取角色列表
 */
export const getRoleListAll = () => {
  return axios.get(`${base.admin_url}/cloud/user/get_role`)
}

/**
 * 新增角色
 * @param {String} name 角色名称
 */
export const addRole = (name) => {
  return axios.post(`${base.admin_url}/cloud/user/add_rule_group`, { name })
}

/**
 * 编辑角色
 * @param {String} name 角色名称
 * @param {Number} group_id 角色id
 */
export const editRole = (name, group_id) => {
  return axios.post(`${base.admin_url}/cloud/user/edit_rule_group`, { group_id, name })
}

/**
 * 删除角色
 * @param {Number} id 角色id
 */
export const deleteRole = (id) => {
  return axios.post(`${base.admin_url}/cloud/user/delete_role`, { id })
}

/**
 * 获取子账户页面、功能权限
 */
export const getAccountPermission = () => {
  return axios.get(`${base.admin_url}/cloud/user/get_yc_rule`)
}

/**
 * 获取广告账户所属用户组、用户/获取全部用户组、用户
 */
export const getAccountRule = (data) => {
  return axios.get(`${base.admin_url}/cloud/user/acc_rule`, {
    params: data,
  })
}

/**
 * 编辑广告账户所属用户组、用户
 */
export const editAccountRule = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/edit_acc_rule`, data)
}

/**
 * 查看广告账户资产
 * @param {Number} u_id 用户id
 * @returns
 */
export const getAccountAsset = (u_id) => {
  return axios.get(`${base.admin_url}/cloud/user/get_account?u_id=${u_id}`)
}

/**
 * 获取可转让超管的所有管理员
 */
export const getAdminList = () => {
  return axios.get(`${base.admin_url}/cloud/user/get_admin`)
}

/**
 * 转让超管
 * @param {Number} u_id 被转让的管理员id
 */
export const transferAdmin = (u_id) => {
  return axios.post(`${base.admin_url}/cloud/user/transfer_admin`, { u_id })
}

/**
 * 查看用户可用额度和可分配额度
 * @param {Number} u_id 用户id
 */
export const getUserBalance = (u_id) => {
  return axios.get(`${base.admin_url}/cloud/user/get_user_balance?u_id=${u_id}`)
}

/** 查看所有子账户的可用额度 */
export const getAllUserBalance = () => {
  return axios.get(`${base.admin_url}/cloud/user/get_all_balance`)
}

/**
 * 余额回收
 * @param {*} u_id 余额回收的用户id
 */
export const balanceRecovery = (u_id) => {
  return axios.post(`${base.admin_url}/cloud/user/balance_recovery`, { u_id })
}

/**
 * 增减可用余额
 * @param {*} data
 */
export const adjustBalance = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/changes_balance`, data)
}

/**
 * 查看流水日志
 * @param {*} data
 */
export const getBalanceLog = (data) => {
  return axios.get(`${base.admin_url}/cloud/user/view_stream_log`, { params: data })
}

/**
 * 导出流水日志
 * @param {Number} u_id 用户id
 */
export const exportBalanceLog = (u_id) => {
  return axios.get(`${base.admin_url}/cloud/user/view_stream_log_export?u_id=${u_id}`, {
    responseType: 'blob',
  })
}

/**
 * 新增子账户
 * @param {Object} data
 */
export const addSubAccount = (data) => {
  return axios.post(`${base.admin_url}/cloud/user/add_sub_user`, data)
}
