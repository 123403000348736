<template>
  <div class="registerBox">
    <img src="@/assets/images/base/register/box.svg" alt="" class="boxImg">
    <div class="registerLeft">
      <div class="registerBg" style="display: none">
        <img src="@/assets/images/registerBgMobile.png" alt="" />
      </div>
      <div class="logoBox">
        <img src="@/assets/registerLogo.png" alt="" />
      </div>
      <div class="systemName">YinoCloud易诺云</div>
      <div class="systemIntro">
        <span>三步完成企业认证，即可享受极速开户服务</span>
        <span>60s自助开户信息填写，最快当天成功开通广告账户，广告费用随时随地自助充值</span>
        <span>Facebook、TikTok、Microsoft、Google等主流海外广告账户一键开户，帮助广告主快速开展海外营销</span>
      </div>
      <div class="guideBox">
        <div class="title">
          <img src="@/assets/iconImg/icon_guide.png" alt="" />
          如何使用YinoCloud易诺云开启出海之路
        </div>
        <div class="content">
          <span>1.登录YinoCloud易诺云</span>
          <span style="width: 120px;">2.完成企业认证</span>
          <span style="width: 120px;">3.免费自助开户</span>
        </div>
      </div>
    </div>
    <div class="registerRight">
      <div class="content">
        <div class="titleBox">
          <div class="title">欢迎使用YinoCloud易诺云</div>
          <div class="subTitle">专业的一站式海外广告智能管理系统</div>
          <div class="subTitle" style="margin-top: 10px;" v-if="businessName">{{ businessName }}邀请加入</div>
        </div>
        <div class="registerTitle">注册</div>
        <div class="registerForm">
          <el-form ref="registerFormRef" :model="registerForm" :rules="registerFormRules" label-width="0px"
            class="login-form">
            <el-form-item prop="phone">
              <el-input placeholder="请输入您的手机号" v-model="registerForm.phone" clearable :validate-event="false">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_phone.svg" alt="" />
                    <Phone v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="phoneCode">
              <div class="codeBox">
                <el-input v-model="registerForm.phoneCode" placeholder="请输入您的验证码" clearable :validate-event="false">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <img v-if="isMobile" src="@/assets/iconImg/icon_code.svg" alt="" />
                      <ChatDotRound v-else />
                    </el-icon>
                  </template>
                </el-input>

                <el-button :disabled="!rightPhone" @click="getCode" :size="innerWidth < 1600 ? 'small' : ''">
                  {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入您的密码" v-model="registerForm.password" type="password" show-password clearable
                :validate-event="false">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_password.svg" alt="" />
                    <Lock v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password1">
              <el-input placeholder="请再次输入您的密码" v-model="registerForm.password1" type="password" show-password clearable
                :validate-event="false">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_password.svg" alt="" />
                    <Lock v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="agree">
              <el-row>
                <el-checkbox v-model="isAgree" style="margin-right: 5px"></el-checkbox>
                <div class="agreeBox">
                  <span class="Text">{{ isMobile ? '登录即表示已阅读并同意' : '登录即表示同意' }}</span>
                  <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                    <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
                  </el-link>
                </div>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width: 100%" @click="PhoneRegister" :disabled="isAgree ? false : true"
                :size="innerWidth < 1600 ? 'small' : ''">注册
              </el-button>
            </el-form-item>
          </el-form>
          <div>
            <a @click="goLogin" class="goLogin" target="_blank">
              <span class="brandColor" style="font-size: 14px">使用已有账号登录</span></a>
          </div>
          <div class="recommend">
            <img src="@/assets/images/base/register/hot.svg" alt="">
            一键批量发布广告：使用预设广告模板，新手也能轻松投放
          </div>
        </div>
      </div>
      <div class="copyright">
        <a href="https://beian.miit.gov.cn" target="_blank">
          杭州益刻网络科技有限公司版权所有Copyright © 2020
          <img src="@/assets/officeIcon.png" alt="" />浙ICP备20011628号-1
        </a>
      </div>
    </div>
  </div>

  <el-dialog custom-class="addWXUser" v-if="'add-wx-user' === dialogType" v-model="showDialog" title="" width="430px"
    :show-close="false" :close-on-click-modal="false">
    <img src="@/assets/images/close.png" alt="" class="dialog-close" @click="closeAndRefresh">
    <img src="@/assets/images/skip.png" alt="" class="dialog-skip" @click="closeAndRefresh">
    <img class="server_qrcode" :src="live_code" alt="" />
  </el-dialog>

  <el-dialog custom-class="addWXUserMobile" v-if="'add-wx-user-mobile' === dialogType" v-model="showDialog" title=""
    width="230px" :show-close="false" top="30vh" :close-on-click-modal="false">
    <div class="title">添加企业微信</div>
    <div class="title">获取YinoCloud易诺云开户指南</div>
    <img class="qrcode" :src="live_code" alt="" />
    <div class="button" @click="closeAndRefresh">跳过</div>
  </el-dialog>
</template>

<script>
import base from '../request/base'
import { getBusiness, refreshUserInfo } from '@/request/api/business'
import google from '@/base/google'

export default {
  name: 'Register',
  data() {
    window._agl = window._agl || [];
    (function () {
      _agl.push(
        ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
      );
      (function () {
        var agl = document.createElement('script');
        agl.type = 'text/javascript';
        agl.async = true;
        agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(agl, s);
      })();
    })();
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入有效的电话号码'))
    }
    return {
      innerWidth: window.innerWidth,
      base: base,
      registerToken: '',
      userInfo: '',
      userId: '',
      registerForm: {
        type: 'code',
        phone: '',
        phoneCode: '',
        password: '',
        password1: '',
      },
      registerFormRules: {
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: checkMobile },
        ],
        phoneCode: [{ required: true, message: '请输入验证码' }],
        password: [
          { required: true, message: '请输入密码' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
          },
        ],
        password1: [
          { required: true, message: '请再次输入密码' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
          },
        ],
      },
      countDownTime: 0,
      isAgree: true,
      dialogType: '',
      showDialog: false,
      live_code: '',
      isMobile: false,
      businessName: null, // 被邀请加入企业名称
      inviteToken: null // 邀请token
    }
  },
  computed: {
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.registerForm.phone
      )
    },
  },
  mounted() {
    // 企业子账户邀请链接进入判断逻辑
    if (window.location.search.split('token=')[1]) {
      this.inviteToken = window.location.search.split('token=')[1]
      const userInfo = this.$store.getters.userInfo
      if (userInfo && userInfo !== 'undefined' && userInfo.id) {
        const token = window.location.search.split('token=')[1]
        getBusiness(token, 1).then(response => {
          refreshUserInfo().then(res => {
            if (res.code == 200) {
              window.localStorage.setItem('userInfo', JSON.stringify(res.user))
              this.$store.dispatch('EDIT_USERINFO', res.user)
            }
          })
          this.$router.push('/home')
          if (response.code === 200) this.$message.success('加入企业成功')
        })
      }
    }
    if (this.$route.query && this.$route.query.token) {
      localStorage.setItem('qbit-token', this.$route.query.token)
    }
    // 判断移动端将isAgree改为true
    if (/Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true
      this.isAgree = true
    }
    this.getBusinessName()
  },
  methods: {
    // 使用已有账号登录
    goLogin() {
      if (this.inviteToken) {
        this.$router.push(`/login?token=${this.inviteToken}`)
      } else {
        this.$router.push('/login')
      }
    },
    getBusinessName() {
      if (!window.location.search.split('token=')[1]) return
      const token = window.location.search.split('token=')[1]
      getBusiness(token, 2).then(res => {
        if (res.code == 200) {
          this.businessName = res.company
        }
      })
    },
    getCode() {
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        const clock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(clock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)
        this.$http.post('login/login', this.registerForm)
      }
    },
    closeAndRefresh() {
      this.dialogType = ''
      if (this.isMobile) {
        this.$router.push('/mobileRegisterSuccess')
        return
      }
      if (this.$store.getters.userInfo.crm_id > 0) {
        this.$router.push('/home')
      } else {
        this.$router.push('/users')
      }
    },
    async addWxUser(res) {
      localStorage.removeItem('qbit-token')
      if (!res.user || !res.user.phone || res.user.exc === 'qbit') {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        this.closeAndRefresh()
        return
      }

      const result = await this.$http.get('https://scrm-wx.weiling.cn/client/mobile/corp_friend', {
        params: {
          corp_id: 'wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg',
          mobile: !res.user || !res.user.phone ? '' : res.user.phone,
        },
      })

      if (result.code == 200 && result.data == true) {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        this.closeAndRefresh()
        return
      }

      let name = res.user.name == null ? '' : res.user.name
      let phone = res.user.phone == null ? '' : res.user.phone
      let company = res.user.company == null ? '' : res.user.company
      let email = res.user.email == null ? '' : res.user.email
      wlRegisterComp.linkToWL(
        {
          wl_qrcode_id: '1605811139499880448', // 需要使用的智能码ID
          client_user_id: res.user.phone, // 用户在贵公司平台的唯一
          sign_mode: false, // 可选参数 不传时默认false 是否校验签名
          sign: '', //string 可选参数 sign_mode为true时传输 加密后的签名
          current_time: 0, //可选参数 sign_mode为true时传输 加密签名时使用的毫秒时间戳
          formData: {
            联系人: name,
            手机号: phone,
            公司名称: company,
            邮箱: email,
          }, // 用户填写的注册或登录信息，value可以为空，但key值必填
          is_update: false, // 此次提交是否为数据更新，默认false
        },
        (data) => {
          if (data.msg == '当前用户已加好友' || data.code !== 200) {
            window.localStorage.setItem('userInfo', JSON.stringify(res.user))
            this.$store.dispatch('EDIT_USERINFO', res.user)
            this.closeAndRefresh()
            return
          }
          if (data.code !== 200) {
            window.localStorage.setItem('userInfo', JSON.stringify(res.user))
            this.$store.dispatch('EDIT_USERINFO', res.user)
            return
          }
          this.live_code = data.data.live_code
          wlRegisterComp.checkUserState(
            res.user.phone, // 用户在客户平台的唯一ID
            (data) => {
              window.localStorage.setItem('userInfo', JSON.stringify(res.user))
              this.$store.dispatch('EDIT_USERINFO', res.user)
              if (data.data.is_added == false && this.isMobile) {
                this.dialogType = 'add-wx-user-mobile'
                this.showDialog = true
                return
              }
              if (data.data.is_added == false && !this.isMobile) {
                this.dialogType = 'add-wx-user'
                this.showDialog = true
              } else {
                this.dialogType = ''
                this.showDialog = false
                this.closeAndRefresh()
              }
            }
          )
        }
      )
    },
    PhoneRegister() {
      this.$refs.registerFormRef.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await this.$http.post('login/register', {
          phone: this.registerForm.phone,
          phoneCode: this.registerForm.phoneCode,
          password: this.registerForm.password,
          password1: this.registerForm.password1,
          token: location.search.split('token=').length > 1 ? location.search.split('token=')[1] : '',
          source: this.$route.query && this.$route.query.source ? this.$route.query.source : '',
        })
        if (res.code != 200) return this.$message.error(`${res.msg}`)
        // 百度转化代码安装-表单提交类
        window._agl && window._agl.push(['track', ['success', { t: 3 }]])
        // this.$message.success('注册成功')
        this.addWxUser(res)
      })
    }
  },
}
</script>
<!--<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10868599597"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10868599597'); </script>-->

<style lang="less" scoped>
a {
  text-decoration: none;
}

.goLogin {
  display: block;
  text-align: center;
  cursor: pointer;
}

.recommend {
  position: relative;
  width: 456px;
  height: 48px;
  background: linear-gradient(270deg, #38FBE1 0%, #2B82FF 100%);
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  margin-top: 24px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
  }
}

.registerBox {
  position: relative;
  height: 100vh;
  min-width: 1200px;
  display: grid;
  grid-template-columns: 50% 50%;
  background: url('../assets/images/base/register/bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.registerLeft {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 60px;
}

.logoBox {
  width: 263px;
  height: 48px;
  margin-bottom: 40px;
}

.logoBox img {
  width: 100%;
}

.boxImg {
  position: absolute;
  top: 32%;
  left: 3%;
  width: 40%;
  height: 60%;
}

.systemName {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #262626;
  font-weight: 500;
  margin-bottom: 24px;
}

.systemIntro {
  font-size: 16px;
  color: #595959;

  span {
    display: inline-block;
    line-height: 32px;
  }
}

.guideBox {
  position: absolute;
  left: 60px;
  bottom: 60px;
}

.guideBox .title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #262626;
  margin-bottom: 8px;
  font-weight: 500;
}

.guideBox .title img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.guideBox .content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #595959;
  ;

  span {
    display: inline-block;
    width: 175px;
    text-align-last: justify;
    padding-left: 37px;
    line-height: 24px;
  }
}

.registerRight {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.registerRight .content {
  margin: 0 auto;
}

.titleBox {
  margin-bottom: 40px;
}

.titleBox .title {
  font-size: 36px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 16px;
}

.titleBox .subTitle {
  font-size: 18px;
  color: #595959;
  text-align: center;
}

.registerTitle {
  font-size: 24px;
  color: #262626;
  text-align: center;
  margin-bottom: 32px;
}

.registerForm {
  width: 440px;
}

.codeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.codeBox .el-input {
  width: 298px;
}

.codeBox .el-button {
  width: 134px;
}

@media screen and (max-width: 1600px) {
  .titleBox {
    margin-bottom: 20px !important;

    .title {
      font-size: 32px !important;
      margin-bottom: 8px !important;
      text-align-last: justify;
    }

    .subTitle {
      font-size: 16px !important;
    }
  }

  .registerTitle {
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }

  .recommend {
    height: 38px;
    line-height: 38px;
    margin-top: 16px !important;

    img {
      top: -6px !important;
    }
  }

  :deep(.el-input) {
    height: 32px !important;
  }

  :deep(.el-input__inner) {
    height: 32px !important;
  }

  :deep(.el-form-item) {
    margin-bottom: 20px !important;
  }

  :deep(.el-form-item:last-child) {
    margin-bottom: 16px !important;
  }

  :deep(.el-form-item.agree) {
    margin-bottom: 16px !important;
  }

  :deep(.el-form-item__content) {
    line-height: 32px !important;

    div:not(.el-form-item__error) {
      height: 32px !important;
      line-height: 32px !important;
    }
  }

  :deep(.el-checkbox) {
    height: 32px !important;
  }
}

.copyright {
  position: absolute;
  bottom: 40px;
  font-size: 12px;
}



.copyright a {
  display: flex;
  align-items: center;
  color: #595959;
}

.copyright a img {
  width: 14px;
  height: 14px;
}

.el-input__icon {
  height: 100% !important;
  display: flex;
  align-items: center;
  margin-left: 6px;
}

/* 移动端自适应样式 */
@media screen and (max-width: 1200px) {
  .registerBox {
    width: 100vw !important;
    display: block !important;
    min-width: 0 !important;
    min-height: 0 !important;

    .registerLeft {
      position: relative;
      height: 100vh !important;
      background: none !important;
      background-size: 30% auto;
      padding: 0 !important;
      z-index: 1;

      .logoBox {
        width: 120px !important;
        height: 22px !important;
        position: absolute;
        top: 52px;
        left: 20px;
      }

      .registerBg {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 33%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .systemName {
        display: none !important;
      }

      .systemIntro {
        display: none !important;
      }

      .guideBox {
        display: none !important;
      }
    }

    .registerRight {
      position: absolute !important;
      left: 0;
      bottom: 0;
      height: 71vh;
      width: 100vw !important;
      z-index: 99;
      border-radius: 12px 12px 0 0;
      background-color: #fff;

      .content {
        width: 100%;
        height: 100% !important;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .registerTitle {
          display: none !important;
        }

        .titleBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0 !important;
          margin-bottom: 20px !important;

          .title {
            width: 239px;
            height: 28px;
            font-size: 20px !important;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            margin-bottom: 8px !important;
          }

          .subTitle {
            width: 192px;
            height: 20px;
            font-size: 12px !important;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }

        .registerForm {
          width: 315px !important;
          flex: 1;

          .login-form {
            height: 100%;
            position: relative;

            .el-input__icon {
              margin-left: 10px;
            }

            .el-input :deep(.el-input__inner) {
              padding-left: 40px;
            }

            .codeBox {
              .el-input {
                width: 200px !important;
              }

              .el-button {
                width: 102px !important;
                display: flex;
                height: 32px;
                justify-content: center;
                align-items: center;
                color: #999999;
              }
            }

            .el-checkbox {
              display: none;
            }

            .agree {
              position: absolute;
              bottom: 4%;
              width: 100%;
              margin-bottom: 0px;

              .agreeBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .Text {
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #666666;
                  line-height: 20px;
                }

                .el-link {
                  span {
                    font-size: 12px !important;
                  }
                }
              }
            }
          }

          .el-input :deep(.el-input__inner) {
            height: 48px;
          }

          .el-input :deep(.el-input__inner::placeholder) {
            color: #999999;
          }

          .goLogin {
            display: none;
          }
        }
      }

      .copyright {
        display: none !important;
      }
    }
  }

  .bindWeChat :deep(.el-dialog) {
    width: 100% !important;
  }
}
</style>

<style lang="less">
.addWXUser {
  height: 560px !important;
  background: url('../assets/images/popBG.png') no-repeat;
  background-size: 100%;
}

.addWXUser .el-dialog__header {
  padding: 0 !important;
  border: 0 !important;
}

.addWXUser .el-dialog__body {
  padding: 0 !important;
}

.dialog-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.dialog-skip {
  width: 108px;
  height: 40px;
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.server_qrcode {
  width: 268px;
  height: 268px;
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
}

.addWXUserMobile {

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 32px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-family: 'PingFangSC-Regular';
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .qrcode {
      margin-top: 16px;
      width: 120px;
      height: 120px;
    }

    .button {
      width: 90px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: transparent;
      color: #2B82FF;
      border-radius: 16px;
      cursor: pointer;
      margin-top: 32px;
      border: 1px solid #2B82FF;
    }
  }

}
</style>
