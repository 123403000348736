<template>
    <div class="container">
        <div class="card">
            <img src="@/assets/ylcloudlogo.svg" alt="logo" class="logo">
            <div class="header">
                <img src="@/assets/images/base/register/success_status.png" alt="">
                <div class="title">注册成功</div>
            </div>
            <div class="content">
                温馨提示: 您已完成注册，请前往PC端<a
                    href="https://yinocloud.yinolink.com/login">https://yinocloud.yinolink.com/login</a>登录，登录后可享受广告账户一键开户、多媒体账户管理、账户快捷充值等特权，快来体验吧！
            </div>
            <div class="footer">
                <button class="button" data-clipboard-text="https://yinocloud.yinolink.com/login">
                    点击复制链接</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import { onMounted } from 'vue';

onMounted(() => {
    let clipboard = new ClipboardJS('.button')
    clipboard.on('success', (e) => {
        ElMessage.success('复制成功')
    })
    clipboard.on('error', (e) => {
        ElMessage.error('复制失败,请手动复制')
    })
})
</script>

<style lang="less" scoped>
.container {
    height: 100vh;
    width: 100vw;
    background: url('../../assets/images/base/register/register_success_bg.png') no-repeat;

    .card {
        padding: 20px 16px;

        .logo {
            width: 120px;
            height: 22px;
        }

        .header {
            margin-top: 32px;
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            .title {
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: normal;
                color: #262626;
            }
        }

        .content {
            width: 100%;
            margin-top: 24px;
            text-align: justify;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: normal;
            color: #666666;

            a {
                color: #2B82FF;
            }
        }

        .footer {
            margin-top: 30%;
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
                width: 315px;
                height: 48px;
                background: #2B82FF;
                border-radius: 157px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: normal;
                color: #FFFFFF;
                text-align: center;
                line-height: 48px;
                cursor: pointer;
                border: none;
            }
        }
    }
}
</style>